<template>
  <div>
    <div id="container"></div>
  </div>
</template>

<script>
import AMapLoader from '@amap/amap-jsapi-loader';

export default {
  name: "gaode",
  data() {
    return {
      map: null //初始化 map 对象
    }
  },
  methods: {
    initMap() {
      AMapLoader.load({
        key: "6ff4fddcc42f335b529901c760286020", //此处填入我们注册账号后获取的Key
        version: "2.0", //指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [''], //需要使用的的插件列表，如比例尺'AMap.Scale'等
      }).then((AMap) => {
        this.map = new AMap.Map("container", { //设置地图容器id
          viewMode: "3D", //是否为3D地图模式
          zoom: 14, //初始化地图级别
          center: [120.306316,30.403588], //初始化地图中心点位置
        });

        // 创建一个 Marker 实例：
        var marker = new AMap.Marker({
          position: new AMap.LngLat(120.306316,30.403588),   // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
          title: ''
        });// 将创建的点标记添加到已有的地图实例：
        this.map.add(marker);

        // marker.setLabel({
        //   // offset: new AMap.Pixel(20, 20),  //设置文本标注偏移量
        //   content: "<div>\n" +
        //       "杭州杭纺科技有限公司\n" +
        //       "电话：0571-82616988\n" +
        //       "地址: 中国杭州萧山新街工业园区新塘头755号</div>", //设置文本标注内容
        //   direction: 'top' //设置文本标注方位
        // });

        this.map.plugin(['AMap.Scale','AMap.ToolBar'],()=>{
          // 在图面添加工具条控件，工具条控件集成了缩放、平移、定位等功能按钮在内的组合控件
          this.map.addControl(new AMap.ToolBar());

          // 在图面添加比例尺控件，展示地图在当前层级和纬度下的比例尺
          this.map.addControl(new AMap.Scale());


        })
      }).catch(e => {
        console.log(e);
      })
    },
  },
  mounted() {
    //DOM初始化完成进行地图初始化
    this.initMap();
  }
}

</script>

<style>
#container {
  width: 100%;
  height: 510px;
}
</style>