<template>
  <div class="download">
    <banner title="联系我们" />
    <div style="width: 100%">
      <div class="contartus">
        <div class="top">
          <p class="titleName">联系我们</p>
          <p style="color: #B1B1B2">CONTART</p>
          <!--            <div class="border"></div>-->
        </div>
        <div id="contart_us_content">
          <div id="left_contart_us">
            <p>杭州三阅信息技术有限公司</p>
            <p>Sanyue IT Co.,Ltd.</p>
            <ul>
              <li>
                <img src="../../assets/icon/place.png">
                <p>地址：浙江省杭州市余杭区南苑街道新远金座2幢902室</p>
              </li>
              <li>
                <img src="../../assets/icon/telephone.png">
                <p>电话：15267043556</p>
              </li>
              <li>
                <img src="../../assets/icon/wangzhi.png">
                <p>网址：www.sanyue100.com</p>
              </li>
              <li>
                <img src="../../assets/icon/email.png">
                <p>邮箱：xinqiang@sanyue100.com</p>
              </li>
            </ul>
          </div>
          <div id="right_contart_us">
            <div id="gongzhonghao">
              <img src="../../assets/img/gongzhonghao.jpg" alt/>
              <p>微信公众号</p>
            </div>
            <div id="describe">
              <p>微信扫描二维码</p>
              <p>一对一在线咨询</p>
              <p>还可获取独家信息</p>
            </div>
          </div>
        </div>
        <div id="map">
          <gaode></gaode>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from "../../components/Banner";
import Gaode from "../map/MapContainer";
export default {
  components: {
    Gaode,
    Banner
  }
};
</script>

<style lang="scss" scoped>
li {
  list-style: none;
}

a {
  text-decoration: none; /* 去除默认的下划线 */
  color: #000; /* 去除默认的颜色和点击后变化的颜色 */
}

* {
  margin: 0;
  padding: 0;
}

.contartus {
  padding:70px 0;
  width: 1200px;
  margin: 0 auto;
  .top {
    text-align: center;
    .titleName {
      font-size: 2em;
      line-height: 2em;
      letter-spacing: 2px;
      font-weight: bold;
    }
  }
  #contart_us_content {
    width: 1200px;
    margin-top: 30px;

    #left_contart_us {
      width: 38%;
      height: 200px;
      margin-right: 20%;
      display: inline-block;

      >p:nth-child(1) {
        font-size: 24px;
        color: black;
      }

      >p:nth-child(2) {
        font-size: 14px;
        line-height: 26px;
      }

      ul {
        margin-top: 10px;
        li{
          img{
            width: 24px;
            height: 24px;
            vertical-align: top;
            padding: 4px 0;
          }
          p{
            padding-left: 5px;
            color: #696969;
            font-size: 16px!important;
            line-height: 2.1;
            display: inline-block;
          }
        }
      }
    }

    #right_contart_us {
      margin-top: 30px;
      display: inline-block;
      #gongzhonghao{
        display: inline-block;

        img{
          width: 180px;
          height: 180px;
        }
        p{
          color: #707070;
          text-align: center;
          font-size: 14px;
        }
      }
      #describe{
        margin-left: 15px;
        margin-top: 80px;
        display: inline-block;
        vertical-align: top;
        p:nth-child(1){
          color: #25874d;
          font-size: 18px;
          font-weight: bold;
        }
        p:nth-child(2){
          margin-top: 15px;
          font-size: 14px!important;
          line-height: 26px!important;
          color: #25874d;
        }
        p:nth-child(3){
          font-size: 14px!important;
          line-height: 26px!important;
          color: #25874d;
        }
      }
    }
  }
  #map{
    width: 1200px;
    height: 510px;
    margin-top: 50px;
  }
}
</style>
